import { Themes } from '../../types'
import { ScoreType, ScoreTypes } from './scoreTypes'

export type ThemesTypes = `${Themes}`

export type Theme = {
  playerEmojis: string[]
  backgroundTextColor: string
  overlayColor: string
  skyColor: string
  contentWrapperStyle: {
    background: string
    inner: string
    outer: string
  }
  scoreType: ScoreTypes
  overrideScoreType?: Partial<ScoreType>
}

export const themes: Record<ThemesTypes, Theme> = {
  classic: {
    playerEmojis: ['🐰', '🦉', '🦊', '🐻', '🐺', '🐿', '🐗', '🦔', '🦨'],
    backgroundTextColor: '11, 61, 86',
    overlayColor: '2, 41, 0',
    skyColor: 'linear-gradient(180deg, #b2d8eb, #c2ebff 50%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'tree',
  },
  halloween: {
    playerEmojis: ['🎃', '🧛‍♂️', '👻', '🧟', '🥀', '💀', '🕷', '🤡', '🦇'],
    backgroundTextColor: '255, 255, 255',
    overlayColor: '48, 22, 61',
    skyColor: 'linear-gradient(160deg, #56337E 0%, #4C90CB 85%)',
    contentWrapperStyle: {
      background: 'linear-gradient(315deg, #38464B 0%, #C2C5E8 100%)',
      inner: 'linear-gradient(135deg, #F7F7F7 0%, #CAD5E2 50%, #8DA7C5 100%)',
      outer: 'linear-gradient(135deg, #DEE5E7 0%, #B5C2D2 100%)',
    },
    scoreType: 'meal',
  },
  winter: {
    playerEmojis: ['🧤', '🧦', '🧣', '🌲', '❄️', '☃️', '⛸', '🧊', '🥶'],
    backgroundTextColor: '77, 102, 190',
    overlayColor: '0, 15, 83',
    skyColor: 'linear-gradient(180deg, #A6DBFF 0.5%, #F2FAFF 100%)',
    contentWrapperStyle: {
      background: 'linear-gradient(315deg, #659DE1 0%, #A8D1FB 100%)',
      inner: 'linear-gradient(135deg, #FFFFFF 0%, #E5F4FF 100%)',
      outer: 'linear-gradient(135deg, #E6F3FC 0%, #C4DEF8 100%)',
    },
    scoreType: 'meal',
  },
  aapi: {
    playerEmojis: ['👘', '🍑', '🌺', '🍤', '🥢', '🎏', '🏮', '🎋', '🐲'],
    backgroundTextColor: '103, 41, 44',
    overlayColor: '103, 41, 44',
    skyColor: 'linear-gradient(187deg, #B7E4BD 5%, #E7BA76 38%, #E87575 95%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'meal',
    overrideScoreType: {
      introText: `Each right answer will add a coin to your mutual score. For every 16 coins you win we will donate a meal to Filipino farmers in their fight to support climate resilience. This helps Filipino farmers protect their crops and provide climate change adaptation support. The World Food Program is also assisting smallholder farmers with production and post-harvest storage as well as increasing their access to markets to sell their produce.`,
    },
  },
  bhc: {
    playerEmojis: ['✊🏾', '🎷', '🎸', '🦸🏾', '⛹🏾‍♂️', '🎙', '🕶', '🗽', '👩🏾‍🎓'],
    backgroundTextColor: '23, 23, 30',
    overlayColor: '23, 23, 30',
    skyColor: 'linear-gradient(180deg, #A6DBFF 0%, #F2FAFF 100%)',
    contentWrapperStyle: {
      background: 'linear-gradient(315deg, #38464B 0%, #C2C5E8 100%)',
      inner: 'linear-gradient(135deg, #F7F7F7 0%, #CAD5E2 50%, #8DA7C5 100%)',
      outer: 'linear-gradient(135deg, #DEE5E7 0%, #B5C2D2 100%)',
    },
    scoreType: 'meal',
    overrideScoreType: {
      introText: `Each right answer will add a coin to your mutual score. For every 16 coins you win we will donate a school meal to students in South Sudan. The World Food Programme ensures children receive the nutritious food they need to live a healthy life and to keep them in school. The programs have proven to increase students’ attendance and nutrition rates.`,
    },
  },
  womens_history: {
    playerEmojis: ['🎀', '👒', '👠', '👗', '🌷', '💐', '💃🏼', '👸🏽', '📚'],
    backgroundTextColor: '130, 25, 171',
    overlayColor: '113, 61, 131',
    skyColor: 'linear-gradient(180deg, #A6DBFF 0%, #F2FAFF 100%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'education',
  },
  earth_day: {
    playerEmojis: ['🐰', '🦉', '🦊', '🐻', '🐺', '🐿', '🐗', '🦔', '🦨'],
    backgroundTextColor: '1, 85, 29',
    overlayColor: '1, 85, 29',
    skyColor: 'linear-gradient(180deg, #B2D8EB 0%, #C2EBFF 50%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'tree',
  },
  'lgbtqia+': {
    playerEmojis: ['✨', '🎉', '💖', '🏳️‍🌈', '🦄', '🐬', '🏳️‍⚧️', '💎', '🦋'],
    backgroundTextColor: '107, 63, 122',
    overlayColor: '107, 63, 122',
    skyColor: 'linear-gradient(180deg, #A6DBFF 0%, #F2FAFF 100%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'meal',
  },
  'hispanic_&_latinx': {
    playerEmojis: ['🌯', '🌮', '❤️‍🔥', '🕺🏽', '💃🏽', '🦙', '🦋', '🦅', '🐍'],
    backgroundTextColor: '40, 32, 66',
    overlayColor: '40, 32, 66',
    skyColor: 'linear-gradient(0deg, #FED0C9 0%, #FFE8E3 54.00%, #BFCAE0 100%)',
    contentWrapperStyle: {
      background: 'linear-gradient(326deg, #ffc670, #f59300, #ffa51f)',
      inner: 'linear-gradient(147deg, #ffc670, #f59300, #ffa51f)',
      outer: 'linear-gradient(147deg, #ffedd6, #ffd299)',
    },
    scoreType: 'meal',
  },
}
